.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #333; /* A dark background for the header */
    color: white; /* Ensures the text stands out */
  }
  
  .navigation a {
    margin-right: 1rem;
    text-decoration: none;
    color: black;
    color: #fff; /* Light color for the links for contrast */

  }
  