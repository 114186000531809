/* CostComparisonGraph.css */
.recharts-responsive-container {
  flex: 1 1 50%; /* Takes half of the container, flexible grow and shrink */
  background-color:rgba(0, 0, 0, 0.7);
  margin-left: 2%;
  height: auto; /* Adjust height based on content */
  padding-right: 0; /* Remove right padding if any */
  border-right: none; 
  box-sizing: border-box;
  padding-top: 2rem;
  width: 100%; /* Each element should take up the full width of its container */


  }
  
  /* Align text style with the table */
  .recharts-text {
    fill: #ffffff; /* White color text for visibility */
    font-family: 'Arial', sans-serif; /* Match the table's font */
    font-size: 14px; /* Assuming the table uses 14px font size */
  }
  
  /* Adjust tooltip to match the dark theme */
/* Tooltip styles */
.recharts-tooltip-wrapper {
    background-color: rgba(50, 50, 50, 0.9); /* Dark semi-transparent background */
    border-radius: 4px;
    border: 1px solid #ffffff; /* Optional: adds a subtle border if it fits the design */
    box-shadow: 0px 0px 5px #ffffff; /* White shadow for a subtle glowing effect */
    padding: 8px;
    color: #ffffff; /* White color text for visibility */
    font-size: 14px; /* Match the font size with the rest of the chart */
  }
  
  /* Simplify and match the legend style to the table */
  .recharts-legend-wrapper {
    bottom: -20px !important;
    color: #ffffff; /* White color text for visibility */
  }
  
  /* Axes and lines color adjustments */
  .recharts-cartesian-axis-tick,
  .recharts-cartesian-axis {
    stroke: #ffffff; /* White color for visibility */
  }
  
  .recharts-cartesian-grid {
    stroke: none; /* Remove grid lines for a cleaner look */
  }
  
  /* Adjust the line colors to match your design palette */
  .recharts-line .recharts-dot {
    stroke: #ffffff; /* Or other color based on your design */
  }
  
  /* Dot styling for cleaner look */
  .recharts-dot {
    fill: #ffffff; /* White dots for consistency */
  }
  
  /* Style the line with a stroke that stands out on the dark background */
  .recharts-line {
    stroke-width: 2px; /* Make lines slightly bolder for better visibility */
  }
  

  