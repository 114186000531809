html, body {
  height: 100%; /* Make sure the html and body are full height */
  margin: 0;
}
body {
  display: flex;
  flex-direction: column; /* Organize children elements in a column */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.wrapper {
  flex: 1; /* Grow to take the available space */
  display: flex;
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  flex-direction: column; /* Stack children vertically */
  position: relative; /* Needed to position the overlay */

}

/* ... other styles ... */

.footer {
  margin-top: auto; /* Push the footer to the bottom */
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Use viewport height to make sure it takes minimum full height of the screen */
}
