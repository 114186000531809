.solar-page {
  font-family: Space Georgia, 'Times New Roman', Times, serif;
  max-width: 90%;
  color: #333; /* Consistent text color */
  padding: 20px;
  margin-bottom: 20px; /* Consistent spacing */
  background-color: #fff; /* Light background for contrast */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  border-radius: 10px; /* Rounded corners like the hero section */
}

/* Typography within sections */
.solar-page h2 {
  color: #ff8c00; /* Echoing the header color for titles */
}

.solar-page p {
  font-size: 14px;
  line-height: 1.6; /* Improved readability */
  color: #666; /* Slightly lighter text for readability */
}


/* Hero Section Styles */
.hero {
  margin-top: 10px;
  background-image: url('../public/homepage/hero.png');  background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px; /* Adjust based on your design */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px; /* Adds rounded corners */
  overflow: hidden; 
  margin-bottom: 20px; /* Adds space below the hero section to prevent overlap with next section */

}

.hero h1 {
  font-size: 48px;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  margin-bottom: 20px; /* Space between text and button */
}

.hero button {
  background-color: #ff8c00;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
}

.hero button:hover {
  background-color: #e07c00;
  transform: scale(1.05);
}

/* Benefits Section Styles */
.benefits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px; /* Adjust this value to reduce gap or overlap */
  padding: 20px;
  background: #fff; /* Optional: Adds background color */
}

.benefit {
  flex: 1;
  margin: 0 20px; /* Spacing between each benefit block */
  text-align: center;
}

.benefit img {
  width: 100%; /* Adjust image size */
  height: auto;
  margin-bottom: 10px;
}

.benefit h2 {
  font-size: 20px;
  color: #333;
}

.benefit p {
  font-size: 16px;
  color: #666;
}

.services {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px; /* Adjust this value to reduce gap or overlap */
  padding: 20px;
  background: #fff; /* Optional: Adds background color */
}

.service {
  flex: 1;
  margin: 0 20px; /* Spacing between each benefit block */
  text-align: center;
}

.service img {
  width: 100%; /* Adjust image size */
  height: auto;
  margin-bottom: 10px;
}

.service h2 {
  font-size: 20px;
  color: #333;
}

.service p {
  font-size: 16px;
  color: #666;
}

/* Adjustments for Responsiveness */
@media (max-width: 768px) {
  .benefits {
    flex-direction: column;
  }

  .benefit {
    margin-bottom: 20px;
  }

  .services {
    flex-direction: column;
  }

  .service {
    margin-bottom: 20px;
  }

  .hero {
    height: auto; /* Makes hero section responsive */
    padding: 40px 20px;
  }

  .hero h1 {
    font-size: 28px;
  }

  .hero button {
    padding: 10px 20px;
    font-size: 16px;
  }
}
