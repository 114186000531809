.wrapper2 {
  background: url('../public/homepage/hero.png') no-repeat center center fixed; 
  background-size: cover; /* Cover ensures the background covers the entire element */
  overflow: hidden;   
  width: 100%;
  display: flex;
  flex: 1; /* Grow to take the available space */

}
.main-content {
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  text-align: center;
  margin: auto; /* Center the main content horizontally */
  padding: 2rem;
  color: white; /* Text color for better contrast against the background */
  transition: width 0.3s ease-in-out; /* Smooth transition for width change */
  flex-wrap: wrap; /* Allows elements to wrap as needed */
  font-family:  Space Georgia, 'Times New Roman', Times, serif; /* Consistent font family */
  overflow: hidden;   
}

.results-container {
  display: flex;
  flex-direction: row; /* Default to row for larger screens */
  justify-content: space-between;
  align-items: stretch; /* Stretch items to fill the container height */
  width: 100%;
  margin-top: 20px;
  overflow-x: hidden; /* Hide horizontal overflow */

}

/* Responsive adjustments */
@media (max-width: 768px) {
  .results-container {
      flex-direction: column; /* Stack vertically on small screens */
  }

  .solar-summary-table, 
  .recharts-responsive-container {
      width: 100%; /* Each child takes full width of the container */
      min-height: 300px; /* Optional: Adjust based on your content needs */
      margin-bottom: 20px; /* Space between the table and graph */
  }
}

.main-content.narrow {
  width: 40%;
}

.main-content.wide {
  width: 80%; /* Larger width when results are displayed */
  overflow-x: hidden; /* Hide horizontal overflow */

}

.main-content h1 {
  margin-bottom: 2rem; /* Space below the header */
  font-size: 2em; /* Large text for the header */
  font-weight: bold;
  z-index: 1;
  position: relative;
}

.main-content::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for text readability */
  z-index: -1; /* Behind the content */
}

/* Flex container for the input and the dropdown */
.flex-row {
  display: flex;
  justify-content: space-between; /* This will create space between your input and select elements */
  margin-bottom: 2rem; /* Space before the button */
  margin-top: 2rem; /* Space before the button */
}

/* Style for the input fields to make them the same size */
.main-content input[type="text"]{
  font-family: inherit;
  width: calc(97% - 0.5rem); /* Adjust the percentage and subtract the gap divided by two */
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}
.main-content input[type="number"]{
  font-family: inherit;
  width: calc(97% - 0.5rem); /* Adjust the percentage and subtract the gap divided by two */
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}
.main-content .flex-row select {
  font-family: inherit;
  width: 100%; /* Make sure they fill their containers */
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  background: white; /* Ensure background consistency */
}
.main-content .flex-row select {
  -webkit-appearance: none; /* Removes default system appearance */
  -moz-appearance: none;
  appearance: none;
  padding-right: 1.5rem; /* Ensure enough space for dropdown arrow */
  background-image: url('data:image/svg+xml;charset=US-ASCII,...'); /* Add your dropdown arrow image encoded in base64 */
  background-repeat: no-repeat;
  background-position: right 0.75rem center; /* Adjust the position as needed */
  background-size: 12px 12px; /* Adjust size as needed */
}

.main-content .dynamic-top-row select {
  width: 100%; /* Make sure they fill their containers */
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  background: white; /* Ensure background consistency */
}
.main-content .dynamic-top-row select {
  -webkit-appearance: none; /* Removes default system appearance */
  -moz-appearance: none;
  appearance: none;
  padding-right: 1.5rem; /* Ensure enough space for dropdown arrow */
  background-image: url('data:image/svg+xml;charset=US-ASCII,...'); /* Add your dropdown arrow image encoded in base64 */
  background-repeat: no-repeat;
  background-position: right 0.75rem center; /* Adjust the position as needed */
  background-size: 12px 12px; /* Adjust size as needed */
}


/* Button styling */
.generate-report {
  padding: .8rem .8rem;
  background-color: #ff8c00; /* Primary button color */
  border:  1px solid #ccc;;
  border-radius: 4px;
  font-size: .8em;
  cursor: pointer;
  color: #000;
  font-weight: bold;
  transition: background-color 0.3s ease;
  z-index: 1;
  position: relative;
  width: 100%; /* Button to take the full width of the container */
}

.generate-report:hover {
  background-color: #FFB300;
}


.generate-report {
  position: relative;
  z-index: 1;
}
.main-content .chill{
  width: 100%;

}

.dynamic-top-row {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 2fr; /* Assign more precise fractional units */
  gap: 10px;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

@media (max-width: 768px) {
  .main-content.narrow {
    width: 80%;
  }
  .dynamic-top-row {
      display: block; /* Switches layout from grid to block */
  }
  .dynamic-top-row > * { /* Targets all child elements */
      width: 100%; /* Each child takes full width of the container */
      margin-bottom: 10px; /* Adds space between stacked elements */
  }
  .main-content input[type="text"]{
    width: 100%;
  }
  .main-content input[type="number"]{
    width: 100%;
  }

  .flex-row {
    flex-direction: column; /* Stack inputs vertically on very small screens */
    display: block; /* Switches layout from grid to block */
    margin-bottom: 0rem; /* Space before the button */
    margin-top: 0rem; /* Space before the button */
    width: 100%;

}
.main-content input,
.main-content select {
    box-sizing: border-box; /* Critical for padding and borders to not add extra width */
}

.flex-row input,
.flex-row select {
    margin: 5px 0; /* Adjust margin for vertical layout */
    width: auto; /* Let each input take full width of the container */
}
.generate-report {
  position: relative;
  width: 100%; /* Button to take the full width of the container */
  font-family:  Space Georgia, 'Times New Roman', Times, serif; /* Consistent font family */

}

}
