.footer {
    flex-shrink: 0; /* Prevent the footer from shrinking */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #333; /* A dark background for the header */
    color: #fff; /* Light text color for contrast */
    padding: 1rem 0;
    text-align: center;
}
  
  .footer a {
    margin-right: 1rem;
    text-decoration: none;
    color: black;
    color: #fff; /* Light color for the links for contrast */
    text-decoration: none;
    margin: 0 0.5rem;
  }


.footer span {
    display: block; /* Or inline-block, depending on your layout */
    margin-top: 0.5rem; /* Space above the copyright text */
    color: #fff;
    /* ... other styles ... */
  }
  
  /* Additional styling as needed */
  