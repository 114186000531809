.terms-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    font-family: Arial, sans-serif;
}

.terms-container h1 {
    color: #f57105f3;
    font-size: 24px;
}

.terms-container h2 {
    color: #f57105f3;
    font-size: 18px;
    margin-top: 20px;
}

.terms-container p {
    color: #000000;
    font-size: 16px;
    text-align: justify;
    line-height: 1.6;
}
