/* SolarSummary.css */

.solar-summary-table {
  flex: 1 1 50%; /* Takes half of the container, flexible grow and shrink */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  overflow-x: auto;
  margin-right: 2%;
  padding-right: 0; /* Remove right padding if any */
  width: 100%; /* Each element should take up the full width of its container */

  border-right: none; 
  box-sizing: border-box; /* Include padding in width calculation */
  }
  
  .solar-summary-table th,
  .solar-summary-table td {
    /* ... existing styles ... */
    padding: 15px; /* Increased padding for better spacing */
  }
  
  .solar-summary-table th {
    /* ... existing styles ... */
    background-color: rgba(33, 37, 41, 0.85); /* Darker shade for headers */
    color: #ffd700; /* Golden color for a premium feel */
  }
  
  .solar-summary-table .highlight {
    font-size: 1.5em; /* Larger size for key figures */
    color: #ffd700; /* Matching the headers */
    font-weight: bold;
  }
  
  .solar-summary-button {
    /* Styles for the Calculate Savings button */
    background-color: #ffa500; /* Orange to stand out */
    color: #ffffff; /* White text */
    padding: 10px 20px;
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Indicates it's clickable */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  }
  
  .solar-summary-button:hover {
    background-color: #e69500; /* Darker orange on hover */
  }
  
  @media (max-width: 768px) {
    .solar-summary-table th,
    .solar-summary-table td {
        padding: 10px; /* Reduce padding on smaller screens */
    }
}