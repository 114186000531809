.privacy-policy-container {
    font-family: Space Georgia, 'Times New Roman', Times, serif;
    max-width: 90%;
    color: #000000; /* Consistent text color */
    padding: 20px;
    margin-bottom: 20px; /* Consistent spacing */
    background-color: #fff; /* Light background for contrast */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    border-radius: 10px; /* Rounded corners like the hero section */
    text-align: center;
}

.privacy-policy-container h1,
.privacy-policy-container h2 {
    color: #f57105f3;
}

.privacy-policy-container p {
    text-align: justify;
    line-height: 1.6;
}
